.App {
  
  &__PageContent {
    display: flex;
    flex-direction: column;
    min-height: 100dvh;
  }
  
  &__Header:global(.mantine-AppShell-header) {
    position: static;
    background-color: rgb(30, 30, 30);
  }
  
  &__Main:global(.mantine-AppShell-main) {
    padding-top: 0;
    padding-bottom: 0;
    flex: 1;
    min-height: auto;
    height: auto;
  }
  
  &__Footer:global(.mantine-AppShell-footer) {
    position: static;
    background-color: rgb(30, 30, 30);
    height: auto;
    padding: 16px;
  }
}